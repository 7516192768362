<!-- eslint-disable max-len -->
<template>
  <div class="is-flex">
    <AppSidebar />
    <b-loading :is-full-page="isFullPage" v-model="isLoading"> </b-loading>
    <div class="main-content">
      <SurveyModal v-if="currentUser.cfa === 'CFAI Provence'"
        url="https://forms.office.com/Pages/ResponsePage.aspx?id=jNKcKOfvyUmO6BYYA1wud9rvKP9GIqBMp7pR4NaArxVUMURETkVBOVU2Rk5EVEhFT0FXRzE2QVJSQi4u"
        content="Répondre nous permettra de mieux vous accompagner, et ne prends que quelques minutes."
        image="https://www.formation-industries-paca.fr/media/Logo-UIMM.png" title="Enquête d'amélioration" />
      <div class="lineCrumbAndAccount ml-auto">
        <AppAccountMenu v-bind:appAccount="crumbs" />
      </div>
      <div class="content-page pt-5" v-if="currentUser">
        <div class="hello mb-5">
          Bonjour
          <span class="has-text-weight-bold">{{ currentUser.first_name }} </span>!
          {{ currentUser.cfa }}
        </div>
        <div v-if="notifications" class="home-notification">
          <b-message class="line-message-warning mb-3" type="is-warning" v-if="notifications.length > 0">
            <b-icon icon="bell" size="is-big" type="is-primary" class="icon-big"></b-icon>
            <span class="mr-auto">
              Vous avez {{ notifications.length }} notification<span v-if="notifications.length > 1">s</span>.</span>
            <b-button type="is-warning" class="ml-auto" @click="goToNotifications">Voir</b-button>
          </b-message>
        </div>
        <div v-if="studentactivities.dates">
          <div v-if="
            currentUser.role === 'apprenant' ||
            currentUser.role === 'manager' ||
            currentUser.role === 'tuteur'
          ">
            <div v-if="studentactivities.dates && datesLate.dates" class="mb-4">
              <div class="titleWithHelp w-100 is-flex justify-content-between align-items-center mb-4" v-if="
                studentactivities.dates.length > 0 ||
                datesLate.dates.length > 0
              ">
                <h2 class="home-title">Mes activités en cours ou à venir</h2>
                <AppHelpMessage title="Vos prochaines activités" message="<b>Retrouvez
            ici les activités que vous avez à faire en ce moment ou les activités à venir.</b>
            Cliquez sur 'voir l'activité' pour commencer.
            <br/>Vous pouvez aussi les retrouver
            dans votre parcours de formation ou dans l'espace 'Planning'." textBtn="Besoin d'aide ?" />

                <router-view to="sequence-afest-edit/"><b-button @click="goThere" type="is-primary" rounded>Voir
                    l'agenda</b-button></router-view>
              </div>
              <div v-if="datesLate.dates">
                <b-message class="line-message-warning mb-2" type="is-danger" v-if="datesLate.dates.length > 0"><b-icon
                    icon="warning" type="is-danger"></b-icon> Attention
                  vous avez {{ datesLate.dates.length }} activités en retard.
                  <b-button @click="goPlanningLate" type="is-danger" class="ml-auto">Voir</b-button>
                </b-message>
              </div>
              <div v-if="studentactivities.dates">
                <div class="columns events is-tablet is-multiline ml-0 mr-0 mt-4" v-if="studentactivities.dates.length">
                  <div class="column card_date card_item" v-for="date in studentactivities.dates.slice(0, 3)"
                    :key="date.id">
                    <div>
                      <div class="day">
                        {{ date.start_date | day }}
                      </div>
                      <div class="hours">
                        <div>
                          du {{ date.start_date | day }} -
                          {{ date.start_date | hour }}
                        </div>
                        <div>
                          au {{ date.end_date | day }} -
                          {{ date.end_date | hour }}
                        </div>
                      </div>
                    </div>
                    <div>
                      <div class="type">{{ date.sequence_type_label }}</div>
                      <div class="name">{{ date.name }}</div>
                      <div class="name_sequence">
                        {{ date.sequence_name }}
                      </div>
                      <div class="user" v-if="currentUser.role != 'apprenant'">
                        <b-icon icon="user" size="is-medium" type="is-primary">
                        </b-icon>{{ date.student_name }}
                      </div>
                      <!-- <div class="user" v-if="currentUser.role != 'apprenant'">
                    {{ date.context }}
                  </div> -->
                    </div>
                    <div class="mt-3" @click="
                      gotoStudentActivity(
                        date.student,
                        date.student_sequence,
                        date.student_activity,
                        date.student_training,
                        date.training
                      )
                      ">
                      <b-button type="is-primary" rounded>Voir l'activité</b-button>
                    </div>
                  </div>
                </div>
                <!-- <div v-else>
                <b-message type="is-info"
                  >Vous n'avez pas de nouvelle date programmée.</b-message
                >
              </div> -->
              </div>
            </div>
          </div>
        </div>
        <router-link v-if="
          currentUser.role === 'apprenant' &&
          currentUser.student_trainings.length > 0
        " :to="'/formation-apprenant/' +
          currentUser.student_trainings[0].training_id +
          '/' +
          currentUser.id +
          '/' +
          currentUser.student_trainings[0].student_training_id +
          '/parcours'
          ">
          <div class="card_item btn pointer has-text-centered">
            <div class="mt-auto mb-auto mr-auto ml-auto">
              <div class="title-paragraph mb-1 pt-4">
                <span>Voir mon parcours de formation</span>
              </div>
              <div class="nametraining mb-4">
                {{ currentUser.student_trainings[0].name }}
              </div>
            </div>
          </div>
        </router-link>
        <!-- <div class="card" v-if="currentUser.role === 'apprenant'">
          <div
            v-for="student in currentUser.student_trainings"
            :key="student.id"
            class="card mb-4"
          >
            <div class="card-content">
              <div class="media align-items-center">
                <div class="media-left">
                  <b-icon icon="user" type="is-medium"></b-icon>
                </div>
                <div class="media-content">
                  <div class="columns is-vcentered">
                    <div class="column">
                      <p class="title is-4" v-if="student.name">
                        {{ student.name }}
                      </p>
                    </div>
                    <div class="column is-narrow is-flex">
                      <router-link
                        :to="
                          '/formation-apprenant/' +
                          student.training_id +
                          '/' +
                          currentUser.id +
                          '/' +
                          student.student_training_id +
                          '/waypoint'
                        "
                      >
                        <b-button
                          type="is-primary"
                          class="ml-auto mt-auto mb-auto mr-1"
                          rounded
                        >
                          livret
                        </b-button>
                      </router-link>
                      <router-link
                        :to="
                          '/formation-apprenant/' +
                          student.training_id +
                          '/' +
                          currentUser.id +
                          '/' +
                          student.student_training_id +
                          '/parcours'
                        "
                      >
                        <b-button
                          type="is-primary"
                          class="ml-auto mt-auto mb-auto"
                          rounded
                        >
                          parcours
                        </b-button>
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> -->
        <!------->
        <div class="card" v-if="
          currentUser.role === 'tuteur' &&
          currentUser.student_trainings.length > 0
        ">
          <div v-for="student in currentUser.student_trainings" :key="student.student_training_id" class="card mb-4">
            <div class="card-content">
              <div class="media align-items-center">
                <div class="media-left">
                  <b-icon icon="user" type="is-medium"></b-icon>
                </div>
                <div class="media-content">
                  <div class="columns is-vcentered">
                    <div class="column">
                      <p class="title is-4" v-if="student.name">
                        {{ student.name }}
                      </p>
                      <p class="subtitle is-6">
                        {{ student.student }}
                      </p>
                    </div>
                    <div class="column is-narrow is-flex">
                      <b-button @click="
                        gotoStudentTraining(
                          student.student_id,
                          student.student_training_id,
                          student.training_id,
                          'waypoint'
                        )
                        " type="is-primary" class="ml-auto mt-auto mb-auto mr-1" rounded>
                        livret
                      </b-button>
                      <b-button @click="
                        gotoStudentTraining(
                          student.student_id,
                          student.student_training_id,
                          student.training_id,
                          'parcours'
                        )
                        " type="is-primary" class="ml-auto mt-auto mb-auto" rounded>
                        parcours
                      </b-button>
                    </div>
                  </div>
                </div>
              </div>
              <ActivitiesInProgress :student_training="student.student_training_id" :student_id="student.student_id"
                :training="Number(student.training_id)" :isOpenActivities="false" />
            </div>
          </div>
        </div>
        <!------->
        <div v-if="oneTraining === false" style="margin-bottom: 3rem" class="pt-3">
          <div v-if="
            currentUser.role === 'apprenant' &&
            user.related_trainings_as_student
          ">
            <div class="card_item card_home columns align-items-center ml-0 mt-0" id="cardHomeTrainingStudent" v-if="
              currentUser.role === 'apprenant' &&
              user.related_trainings_as_student.length > 1
            ">
              <b-icon icon="training" type="is-primary" class="mb-0"></b-icon>
              <div class="title mb-0 ml-5 mr-5">Formations</div>
              <!-- <div class="subtitle mb-0 mt-0">Accédez à vos formations</div> -->
              <div class="ml-auto">
                <router-link to="/formations">
                  <b-button type="is-primary" is-rounded style="display: inline-block" class="btn-formation">Accédez à
                    vos formations</b-button></router-link>
              </div>
            </div>
          </div>
        </div>
        <div v-else :class="currentUser.role === 'tuteur'
          ? 'one-activity-block'
          : 'student-block'
          ">
          <div class="card_item btn pointer has-text-centered" @click="gotoTraining('parcours')"
            v-if="currentUser.role === 'apprenant'">
            <div class="mt-auto mb-auto mr-auto ml-auto">
              <div class="title-paragraph mb-1 pt-4">
                <span>Voir mon parcours de formation</span>
              </div>
              <div v-if="training" class="nametraining mb-4">
                {{ training.name }}
              </div>
            </div>
          </div>
          <div class="card" v-if="currentUser.role === 'tuteur'">
            <div v-for="student in students" :key="student.id" class="card mb-4">
              <div class="card-content">
                <div class="media align-items-center">
                  <div class="media-left">
                    <b-icon icon="user" type="is-medium"></b-icon>
                  </div>
                  <div class="media-content">
                    <div class="columns is-vcentered">
                      <div class="column">
                        <p class="title is-4" v-if="student.name">
                          {{ student.name }}
                        </p>
                        <p class="subtitle is-6" v-if="training">
                          {{ training.name }}
                        </p>
                      </div>
                      <div class="column is-narrow is-flex">
                        <b-button @click="
                          gotoStudentTraining(
                            student.id,
                            student.studenttraining,
                            'waypoint'
                          )
                          " type="is-primary" class="ml-auto mt-auto mb-auto mr-1" rounded>
                          livret
                        </b-button>
                        <b-button @click="
                          gotoStudentTraining(
                            student.id,
                            student.studenttraining,
                            'parcours'
                          )
                          " type="is-primary" class="ml-auto mt-auto mb-auto" rounded>
                          parcours
                        </b-button>
                      </div>
                    </div>
                  </div>
                </div>
                <ActivitiesInProgress :student_training="student.studenttraining" :student_id="student.name"
                  :training="Number(trainingId)" :isOpenActivities="false" />
              </div>
            </div>
          </div>
        </div>
        <!-- video tutorial -->
        <div v-if="currentUser.role === 'apprenant'">
          <div class="columns is-fullhd">
            <div class="column">
              <div class="card_item p-0" v-if="currentUser.cfa === 'CFAI Provence'">
                <div class="card-image">
                  <iframe class="w-100" width="560" height="315" src="https://www.youtube.com/embed/4vy0SAevt4M"
                    title="YouTube video player" frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen></iframe>
                </div>
                <div class="card-content">
                  <div class="media">
                    <div class="media-content">
                      <p class="title is-4">Prise en main d'inSitu</p>
                      <p class="subtitle is-6 has-text-primary mb-2">
                        CFAI Provence, rentrée 2022
                      </p>
                    </div>
                  </div>

                  <div class="content">
                    Voici un guide d'utilisation pour inSitu, afin de vous
                    permettre de gérer votre alternance tout au long de l'année.
                  </div>
                </div>
              </div>
            </div>
            <div class="column h-100">
              <router-link to="/aide">
                <div class="card_item p-0" v-if="currentUser.cfa === 'CFAI Provence'">
                  <div class="card-image has-background-primary mb-2">
                    <div class="is-flex img-cover">
                      <b-icon icon="question" class="has-text-white m-auto icon-big" style="margin: auto"></b-icon>
                    </div>
                  </div>
                  <div class="card-content h-100">
                    <div class="media">
                      <div class="media-content">
                        <p class="title is-4">Besoin d'aide ?</p>
                        <p class="subtitle is-6 has-text-primary mb-2">
                          CFAI Provence, rentrée 2022
                        </p>
                      </div>
                    </div>

                    <div class="content has-text-black">
                      N'hésitez pas à contacter le support pour demander de
                      l'aide ou proposer des améliorations.
                    </div>
                  </div>
                </div>
              </router-link>
            </div>
          </div>
        </div>
        <!-- video tutorial ends -->
        <!-- video tutorial -->
        <div v-if="currentUser.role === 'tuteur'" class="mt-5 pt-5">
          <div class="columns is-fullhd">
            <div class="column">
              <div class="card_item p-0" v-if="currentUser.cfa === 'CFAI Provence'">
                <div class="card-image">
                  <iframe class="w-100" width="560" height="315" src="https://www.youtube.com/embed/Ilofo0g18pg"
                    title="YouTube video player" frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen></iframe>
                </div>
                <div class="card-content">
                  <div class="media">
                    <div class="media-content">
                      <p class="title is-4">Prise en main d'inSitu</p>
                      <p class="subtitle is-6 has-text-primary mb-2">
                        CFAI Provence, rentrée 2022
                      </p>
                    </div>
                  </div>

                  <div class="content">
                    Voici un guide d'utilisation pour inSitu, afin de vous
                    permettre d'accompagner votre alternant tout au long de
                    l'année.
                  </div>
                </div>
              </div>
            </div>
            <div class="column h-100">
              <router-link to="/aide">
                <div class="card_item p-0" v-if="currentUser.cfa === 'CFAI Provence'">
                  <div class="card-image has-background-primary mb-2">
                    <div class="is-flex img-cover">
                      <b-icon icon="question" class="has-text-white m-auto icon-big" style="margin: auto"></b-icon>
                    </div>
                  </div>
                  <div class="card-content h-100">
                    <div class="media">
                      <div class="media-content">
                        <p class="title is-4">Besoin d'aide ?</p>
                        <p class="subtitle is-6 has-text-primary mb-2">
                          CFAI Provence, rentrée 2022
                        </p>
                      </div>
                    </div>

                    <div class="content has-text-black">
                      N'hésitez pas à contacter le support pour demander de
                      l'aide ou proposer des améliorations.
                    </div>
                  </div>
                </div>
              </router-link>
            </div>
          </div>
        </div>
        <!-- video tutorial ends -->
        <div v-if="
          (currentUser.role === 'apprenant' && currentUser.cfa === 'itii') ||
          (currentUser.role === 'tuteur' && currentUser.cfa === 'itii')
        " class="mt-5">
          <div v-if="studentId && studenttraining && this.currentStep.part_id"
            class="todo card card_item is-flex mb-0 is-clickable"
            @click="gotoNow(studentId, studenttraining, currentStep.part_id)">
            <div class="is-flex w-100">
              <div class="head-card is-flex">
                <div class="m-auto has-text-centered">
                  <div class="title mb-0">A faire</div>
                  <span class="has-text-primary"> en ce moment</span>
                </div>
              </div>
              <div class="content-card has-text-centered">
                <div class="m-auto w-100">
                  <div class="name">{{ this.currentStep.part_name }}</div>
                </div>

                <div class="b-steps is-small w-100 mt-4">
                  <nav class="steps is-rounded">
                    <ul class="step-items">
                      <li class="step-item is-previous" v-for="validation in currentStep.validations"
                        :key="validation.id">
                        <a class="step-link">
                          <div class="step-marker mb-3">
                            <img :src="require(`@/assets/img/steps/status6.svg`)" alt="" class="img_status mx-auto" />
                          </div>
                          <div class="step-details">
                            <span class="step-title">
                              {{ validation.name }}</span>
                          </div>
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- webinar -->
        <div v-if="currentUser.role === 'tuteur'">
          <div class="columns" v-if="currentUser.cfa === 'CFAI Provence'">
            <div class="column is-half" v-for="webinar in webinars" :key="webinar.id">
              <a :href="'https://insituapp.io/webinaires/' + webinar.slug" target="_blank">
                <div class="card_item p-0">
                  <div class="card-image">
                    <img :src="webinar.image.path" width="100%" />
                  </div>
                  <div class="card-content">
                    <div class="media">
                      <div class="media-content">
                        <p class="title is-4">
                          Webinaire : {{ webinar.title }}
                        </p>
                        <p class="subtitle is-6 has-text-primary mb-2">
                          {{ webinar.channel }}
                        </p>
                      </div>
                    </div>

                    <div class="content has-text-black">
                      Rendez-vous sur le site inSitu pour voir les différents
                      créneaux disponibles et vous inscrire.
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
        <!-- webinar ends -->
        <!-- coach -->
        <div v-if="currentUser.role === 'formateur' && user.edit_rights === 4">
          <StudentTrainingsList />
        </div>
        <div v-if="
          (currentUser.role === 'formateur' && user.edit_rights !== 4) ||
          currentUser.role === 'coordinateur' ||
          currentUser.role === 'superviseur' ||
          currentUser.role === 'manager'
        ">
          <div class="columns is-desktop is-multiline">
            <div class="column" :class="currentUser.role === 'formateur' ? 'is-12' : ''">
              <div class="card_item card_home">
                <b-icon icon="training" type="is-primary"></b-icon>
                <div class="title">Formations</div>
                <div class="subtitle">
                  Suivez l’évolution de chaque formation de façon globale ou par
                  apprenant
                </div>
                <div>
                  <router-link to="/formations">
                    <b-button type="is-primary" is-rounded>Formations en cours</b-button></router-link>
                </div>
              </div>
            </div>
            <div class="column" v-if="currentUser.role !== 'tuteur'">
              <div class="card_item card_home">
                <b-icon icon="user" type="is-primary"></b-icon>
                <div class="title">Utilisateurs</div>
                <div class="subtitle">
                  Suivez l'activité de chaque acteur
                  <!-- et Modifiez les droits de
                  chaque acteur -->
                </div>
                <div>
                  <router-link to="/utilisateurs">
                    <b-button type="is-primary" is-rounded>Tous les utilisateurs</b-button></router-link>
                </div>
              </div>
            </div>
          </div>
          <div class="columns" v-if="
            currentUser.role === 'coordinateur' ||
            currentUser.role === 'superviseur'
          ">
            <div class="column is-full">
              <div class="card_item card_home has-text-left">
                <div class="columns is-vcentered is-desktop">
                  <div class="column">
                    <div class="title">Maquettes</div>
                    <div class="subtitle">
                      Créez des parcours et des sessions de formation
                    </div>
                    <router-link to="/maquettes">
                      <b-button type="is-primary" is-rounded>Toutes les maquettes</b-button></router-link>
                  </div>
                  <div class="column columns card_columns">
                    <div class="column">
                      <router-link to="/creer-une-maquette" class="card-button">
                        <div class="card_item has-text-centered h-100">
                          <div>
                            <div>
                              <b-icon icon="framework" type="is-primary"></b-icon>
                            </div>
                            <div>
                              <b>créer une maquette</b><br />
                              de formation
                            </div>
                          </div>
                        </div>
                      </router-link>
                    </div>
                    <div class="column">
                      <router-link to="/maquettes" class="card-button">
                        <div class="card_item has-text-centered h-100">
                          <div>
                            <b-icon icon="training" type="is-primary"></b-icon>
                          </div>
                          <div>
                            <b>ouvrir une formation</b><br />
                            à partir d’une maquette
                          </div>
                        </div>
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- video tutorial -->
        <div v-if="currentUser.role === 'formateur'">
          <div class="columns is-fullhd">
            <div class="column">
              <div class="card_item p-0" v-if="currentUser.cfa === 'CFAI Provence'">
                <div class="card-image">
                  <iframe class="w-100" width="560" height="315" src="https://www.youtube.com/embed/tTQ65J-2Bg8"
                    title="YouTube video player" frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen></iframe>
                </div>
                <div class="card-content">
                  <div class="media">
                    <div class="media-content">
                      <p class="title is-4">Prise en main d'inSitu</p>
                      <p class="subtitle is-6 has-text-primary mb-2">
                        CFAI Provence, rentrée 2022
                      </p>
                    </div>
                  </div>

                  <div class="content">
                    Voici un guide d'utilisation pour inSitu, afin de vous
                    permettre de gérer vos formations tout au long de l'année.
                  </div>
                </div>
              </div>
            </div>
            <div class="column h-100">
              <router-link to="/aide">
                <div class="card_item p-0" v-if="currentUser.cfa === 'CFAI Provence'">
                  <div class="card-image has-background-primary mb-2">
                    <div class="is-flex img-cover">
                      <b-icon icon="question" class="has-text-white m-auto icon-big" style="margin: auto"></b-icon>
                    </div>
                  </div>
                  <div class="card-content h-100">
                    <div class="media">
                      <div class="media-content">
                        <p class="title is-4">Besoin d'aide ?</p>
                        <p class="subtitle is-6 has-text-primary mb-2">
                          CFAI Provence, rentrée 2022
                        </p>
                      </div>
                    </div>

                    <div class="content has-text-black">
                      N'hésitez pas à contacter le support pour demander de
                      l'aide ou proposer des améliorations.
                    </div>
                  </div>
                </div>
              </router-link>
            </div>
          </div>
        </div>
        <!-- video tutorial ends -->
        <!-- <div
          class="mt-5"
          v-if="
            currentUser.role === 'apprenant' ||
            currentUser.role === 'manager' ||
            currentUser.role === 'tuteur'
          "
        >
          <div v-if="resources">
            <div v-if="resources.length">
              <h2 class="home-title mb-5 mt-5">Mes dernières ressources</h2>
              <div v-for="resource in resources.slice(0, 5)" :key="resource.id">
                {{ resource.name }}
                {{ resource.owner }}
                {{ resource.owner !== currentUser.id }}
                <ResourceCard
                  :resource="resource"
                  v-if="resource.owner !== currentUser.id"
                ></ResourceCard>
              </div>
            </div>
          </div>
          <div v-else>
            <b-message type="is-info">Vous n'avez pas de ressources</b-message>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { mapState } from 'vuex';
import moment from 'moment';
import localization from 'moment/locale/fr';
import _ from 'lodash';
import AppAccountMenu from '@/components/AppAccountMenu.vue';
import AppSidebar from '@/components/AppSidebar.vue';
// import ResourceCard from '@/components/Resources/ResourceCard.vue';
import AppHelpMessage from '@/components/AppHelpMessage.vue';
import ActivitiesInProgress from '@/components/Activity/ActivitiesInProgress.vue';
import StudentTrainingsList from '@/components/Dashboard/StudentTrainingsList.vue';
import SurveyModal from '../components/Surveys/SurveyModal.vue';

moment.updateLocale('fr', localization);

export default {
  components: {
    AppSidebar,
    AppAccountMenu,
    // ResourceCard,
    AppHelpMessage,
    ActivitiesInProgress,
    StudentTrainingsList,
    SurveyModal,
  },
  data() {
    return {
      studenttraining: null,
      oneTraining: false,
      teacherSequences: [],
      studentactivities: [],
      isLoading: false,
      isFullPage: false,
      crumbs: [
        {
          text: 'Formations',
          href: '/maquettes',
          class: 'is-active',
        },
      ],
      trainingId: null,
      trainingName: null,
      trainingDates: null,
      training: null,
      studentTrainingId: null,
      datesLate: Array,
      user: '',
      studentId: null,
      studentName: null,
      currentStep: [],
      students: [],
      severalstudents: false,
      webinars: [],
    };
  },
  filters: {
    day(date) {
      return moment(date).format('D MMM YYYY');
    },
    hour(date) {
      return moment(date).format('H:mm');
    },
  },
  computed: {
    resources() {
      return this.$store.state.resources.resources;
    },
    dates() {
      return this.$store.state.dates;
    },
    // notifications() {
    //   return this.$store.state.notifications;
    // },
    ...mapState('auth', ['currentUser']),
    ...mapState(['notifications']),
  },
  methods: {
    initResources() {
      this.$http.get(`/get_resources/?training=${this.trainingId}&format=json`).then((response) => {
        this.$store.commit('GET_RESOURCES', response.data);
      });
    },
    initLateDates() {
      this.$http.get('/agenda/planning/?prev=1&state=late&format=json').then((response) => {
        // this.endLoading();
        this.datesLate = response.data;
      }).catch((error) => {
        window.console.log(error);
        this.errored = true;
      });
    },
    initDates() {
      // this.startLoading();
      this.$http.get('/agenda/planning/?prev=1&state=inprogress&limit=3&format=json').then((response) => {
        this.initLateDates();
        this.studentactivities = response.data;
        this.studentactivities.dates = this.order(this.studentactivities.dates);
        // this.endLoading();
      }).catch((error) => {
        this.endLoading();
        window.console.log(error);
        this.errored = true;
      });
    },
    initTeacherDates() {
      this.$http.get('/agenda/planning_global/?status=1&limit=3&format=json').then((response) => {
        this.endLoading();
        this.teacherSequences = response.data;
        this.teacherSequences.dates = this.order(this.teacherSequences.dates);
      }).catch((error) => {
        window.console.log(error);
        this.errored = true;
      });
    },
    initDatesForAgenda() {
      this.startLoading();
      if (this.currentUser.role === 'apprenant' || this.currentUser.role === 'tuteur' || this.currentUser.role === 'manager') {
        this.$http
          .get('/agenda/planning/?prev=true&format=json')
          .then((response) => {
            this.$store.commit('GET_DATES', response.data);
            this.endLoading();
          }).catch((error) => {
            window.console.log(error);
            this.errored = true;
          });
      } else {
        this.$http
          .get('/agenda/planning_global/?prev=true&format=json')
          .then((response) => {
            this.$store.commit('GET_DATES', response.data);
          }).catch((error) => {
            window.console.log(error);
            this.errored = true;
          });
      }
    },
    goThere() {
      this.$router.push({
        name: 'Agenda',
      });
    },
    goPlanningLate() {
      this.$router.push({
        name: 'Planning',
        params: { stepNumber: 2 },
      });
    },
    order(array) {
      return _.orderBy(array, 'start_date');
    },
    gotoStudentActivity(studentId, studentSequence, studentActivity, studenttraining, training) {
      // this.$router.push({
      //   name: 'StudentActivityDetailEdit',
      //   params: {
      //     idStudent: studentId,
      //     idSequence: studentSequence,
      //     idActivity: studentActivity,
      //     idStudentTraining: studenttraining,
      //     idTraining: training,
      //     from: 'Dashboard',
      //   },
      // });
      this.$router.push({
        name: 'StudentActivity',
        params: {
          idSequence: studentSequence,
          idTraining: training,
          idStudent: studentId,
          idStudentTraining: studenttraining,
          idActivity: studentActivity,
          from: 'Dashboard',
        },
      });
    },
    getTrainingName(studenttraining) {
      this.$http.get(`/agenda/student_training/${studenttraining}/?query={training{name}}&format=json`).then((response) => {
        this.trainingName = response.data.training.nname;
      });
    },
    startLoading() {
      this.isLoading = true;
    },
    endLoading() {
      this.isLoading = false;
    },
    initUser() {
      this.$http
        .get(`/profile/${this.currentUser.id}/?format=json`)
        .then((response) => {
          this.user = response.data;
          if (this.currentUser.role === 'apprenant'
            || this.currentUser.role === 'manager'
            || this.currentUser.role === 'tuteur') {
            this.initDates();
          }
          if (this.currentUser.role === 'formateur') {
            if (this.user.related_trainings_as_teacher.length > 1) {
              this.startLoading();
              this.initTeacherDates();
            } else {
              // this.$router
              //   .push({
              //     name: 'DashboardManagement',
              //     params: {
              //       idTraining: this.user.related_trainings_as_teacher[0],
              //       tabDashboard: 'default',
              //     },
              //   });
            }
          }
          // if (this.currentUser.role === 'apprenant') {
          //   if (this.user.related_trainings_as_student.length === 1) {
          //     this.trainingId = this.user.related_trainings_as_student;
          //     this.initTraining();
          //     this.initStudentTraining
          // (this.user.related_trainings_as_student, this.currentUser.id);
          //     this.oneTraining = true;
          //     this.studentId = this.currentUser.id;
          //     this.initCurrentStep(this.trainingId);
          //   }
          // }
          // if (this.currentUser.role === 'tuteur') {
          //   if (this.user.related_trainings_as_tutor.length === 1) {
          //     this.trainingId = this.user.related_trainings_as_tutor;
          //     this.initTraining();
          //     if (this.user.related_student_trainings_as_tutor > 1) {
          //       this.severalstudents = true;
          //     } else {
          //       this.studenttraining =
          // this.user.related_student_trainings_as_tutor[0];// eslint-disable-line
          //     }
          //     // this.studenttraining = this.user.related_student_trainings_as_tutor[0]
          //     for (let i = 0; i < this.user.related_student_trainings_as_tutor.length; i += 1) {
          //       this.searchSeveralStudents(this.user.related_student_trainings_as_tutor[i]);
          //     }

          //     this.oneTraining = true;
          //     // this.initResources();
          //     this.initCurrentStep(this.trainingId);
          //   }
          // }
        });
    },
    initTraining() {
      this.$http
        .get(`/trainings/${this.trainingId}/?query={name}&format=json`)
        .then((response) => {
          this.training = response.data;
        });
    },
    initStudentTraining(trainingId, studentId) {
      this.$http
        // .get(`/agenda/student_training_detail/?training_id=${
        // this.user.related_trainings_as_student}&student_id=${
        // this.currentUser.id}&all=0&format=json`)
        .get(`/agenda/student_training/?training=${trainingId}&student=${studentId}&query={id}&format=json`)
        .then((response) => {
          this.studenttraining = response.data[0].id;
        });
    },
    searchStudent(studentTraining) {
      this.$http
        .get(`/agenda/student_training/${studentTraining}/?query={student,student_profile}&format=json`)
        .then((response) => {
          this.studentId = response.data.student;
          this.studentName = response.data.student_profile;
        });
    },
    searchSeveralStudents(studentTraining) {
      this.$http
        .get(`/agenda/student_training/${studentTraining}/?query={student,student_profile}&format=json`)
        .then((response) => {
          this.students.push({
            id: response.data.student,
            name: response.data.student_profile,
            studenttraining: studentTraining,
          });
        });
    },
    gotoTraining(step) {
      this.$router
        .push({
          name: 'StudentTraining',
          params: {
            idTraining: this.trainingId[0],
            idStudent: this.studentId,
            idStudentTraining: this.studenttraining,
            tab: step,
          },
        });
    },
    gotoStudentTraining(studentId, studenttraining, trainingId, step) {
      this.$router
        .push({
          name: 'StudentTraining',
          params: {
            idTraining: trainingId,
            idStudent: studentId,
            idStudentTraining: studenttraining,
            tab: step,
          },
        });
    },
    initCurrentStep(id) {
      if (this.currentUser.cfa === 'itii') {
        this.$http
          .get(`/get_training_booklet_validations/${id}?step=current`)
          .then((response) => {
            this.currentStep = response.data;
          });
      }
    },
    gotoNow(studentId, studentTraining) {
      this.$router.push({
        name: 'StudentTraining',
        params: {
          tab: 'waypoint',
          idTraining: this.trainingId,
          idStudent: studentId,
          idStudentTraining: studentTraining,
          from: 'Dashboard',
        },
      });
      // this.$router.push({
      //   name: 'WaypointitiiEdit',
      //   params: {
      //     idTraining: this.trainingId,
      //     idStudent: studentId,
      //     idStudentTraining: studentTraining,
      //     // idWaypoint: waypointId,
      //     simple_booklet_part_validation: part,
      //     from: 'DashboardManagement',
      //   },
      // });
    },
    goToNotifications() {
      this.$router.push({
        name: 'Notifications',
      });
    },
  },
  mounted() {
    axios.get('https://insituapp.io/api/webinars/available_webinars')
      .then((response) => {
        this.webinars = response.data;
      })
      .catch((error) => {
        window.console.log(error);
        this.errored = true;
      });
  },
  created() {
    if (this.currentUser.role === 'apprenant'
      || this.currentUser.role === 'manager'
      || this.currentUser.role === 'tuteur') {
      this.initUser();
      // this.startLoading();
      // this.initDates();
    } else if (this.currentUser.role === 'formateur') {
      this.initUser();
    } else { this.endLoading(); }
    // this.initDatesForAgenda();
  },
};
</script>

<style lang="scss" scoped>
.hello {
  font-size: 1.6rem;
  font-weight: 500;
  text-align: center;
}

.nametraining {
  font-size: 1.1rem;
}

.card-content {
  padding: 1.5rem 1.5rem;
}

.card-image .img-cover {
  height: 315px;
}

@media (max-width: 768px) {
  .hello {
    font-size: 1.3rem;
  }

  .card_item .title-paragreh {
    font-size: 1.1rem;
  }

  .nametraining {
    font-size: 1rem;
  }

  .btn-formation {
    margin-top: 2rem;
  }

  .media-content {
    overflow: hidden !important;

    button {
      margin-left: 0px !important;
    }
  }

  .media-left {
    display: none !important;
  }

  .card-image .img-cover {
    height: unset;
    min-height: 150px;
  }
}

@media (max-width: 390px) {
  .one-activity-block {
    margin-left: -0.7rem;
    margin-right: -0.7rem;

    .card-content {
      padding: 1rem;
    }

    .title {
      font-size: 1.2rem !important;
    }

    .subtitle {
      font-size: 0.9rem;
    }

    .card-footer-item {
      font-size: 0.9rem;
      line-height: 1.2;
      padding: 0.5rem;
    }
  }
}
</style>
