<!-- eslint-disable max-len -->
<template>
  <section>
    <b-modal v-model="isModalActive" has-modal-card trap-focus>
      <div class="modal-card" style="width: auto">
        <header class="modal-card-head">
          <p class="modal-card-title">{{ title }}</p>
          <button type="button" class="delete" @click="isModalActive = false"></button>
        </header>
        <section class="modal-card-body"
          style="display: flex; align-items: center; justify-content: center; flex-direction: column;">
          <div class="content has-text-centered" style="width: 100%;">
            <img :src="image" :alt="title" class="image"
              style="display: block; width: 25%; height: auto; margin: 0 auto;">
            <p style="margin-top: 20px;">{{ content }}</p>
          </div>
        </section>
        <footer class="modal-card-foot" style="justify-content: center;">
          <b-button label="Je ne veux pas répondre" @click="closeSurvey('refused')"></b-button>
          <b-button label="Répondre" type="is-primary" @click="openSurvey"></b-button>
        </footer>
      </div>
    </b-modal>
  </section>
</template>

<script>
import Cookies from 'js-cookie';

export default {
  props: {
    url: String,
    content: String,
    image: String,
    title: String,
  },
  data() {
    return {
      isModalActive: false,
    };
  },
  mounted() {
    this.checkSurveyCookie();
  },
  methods: {
    checkSurveyCookie() {
      const surveyCookieName = `survey_${this.title.replace(/\s+/g, '_')}`;
      if (!Cookies.get(surveyCookieName)) {
        this.isModalActive = true;
      }
    },
    closeSurvey(status = 'completed') {
      const surveyCookieName = `survey_${this.title.replace(/\s+/g, '_')}`;
      Cookies.set(surveyCookieName, status, { expires: 30 }); // Cookie expires in 30 days
      this.isModalActive = false;
    },
    openSurvey() {
      window.open(this.url, '_blank');
      this.closeSurvey();
    },
  },
};
</script>

<style scoped>
.modal-card {
  max-width: 600px;
}
</style>
